import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { X } from 'lucide-react'
import { ArrowNarrowRight } from '../onboarding/components/ArrowNarrowRight'
import { trackDemoRequest } from '@/lib/hightouch/events'

interface DemoModalProps {
  isOpen: boolean
  // eslint-disable-next-line no-unused-vars
  setIsOpen: (isOpen: boolean) => void
}

const DemoModal: React.FC<DemoModalProps> = ({ isOpen, setIsOpen }) => {
  const [email, setEmail] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(true)
  const [isSuccess, setIsSuccess] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const validateEmail = (email: string) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value
    setEmail(newEmail)
    setIsEmailValid(true)
    setError(null)
  }

  const handleSubmit = async () => {
    const isValid = validateEmail(email)
    setIsEmailValid(isValid)
    if (isValid) {
      try {
        await trackDemoRequest(email)
        setIsSuccess(true)
      } catch (err) {
        setError('An error occurred. Please try again.')
      }
    }
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={() => setIsOpen(false)}
      >
        <div className="flex min-h-screen items-center justify-center p-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-[#19191B] bg-opacity-60" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="w-full max-w-[805px] min-h-[383px] transform overflow-hidden rounded-lg bg-black text-left align-middle shadow-xl transition-all relative flex items-center justify-center md:-mt-44 -mt-24">
              <div className="absolute right-5 sm:right-3 top-5 sm:top-3">
                <button
                  type="button"
                  className="text-white hover:text-gray-200"
                  onClick={() => setIsOpen(false)}
                >
                  <X size={24} />
                </button>
              </div>
              <div className="w-full max-w-[525px] mx-auto px-4 sm:px-8 py-12 sm:py-20">
                <div className="w-full">
                  {isSuccess ? (
                    <div>
                      <h3 className="text-xl font-['Inter'] text-left font-bold text-white mb-1  flex items-center">
                        Success <span className="ml-2 text-green-400">✅</span>
                      </h3>
                      <p className="text-md font-['Inter'] text-left text-gray-300 leading-tight font-medium">
                        Your demo request has been submitted and a member of our
                        team will reach out shortly to get a time scheduled.
                      </p>
                    </div>
                  ) : (
                    <>
                      <div>
                        <Dialog.Title
                          as="h3"
                          className="text-lg xs:text-xl font-['Inter'] font-semibold leading-tight text-white mb-1"
                        >
                          <span className="inline-block sm:inline">
                            Schedule a 30-minute demo with our founder
                          </span>
                        </Dialog.Title>
                        <p className="text-xs sm:text-base font-['Inter'] text-gray-300 mb-6 sm:mb-8 font-medium">
                          Learn how Scout can solve your use case
                        </p>
                      </div>

                      <div className="space-y-2 sm:space-y-2">
                        <div className="space-y-2">
                          <input
                            type="email"
                            placeholder="Email Address"
                            value={email}
                            onChange={handleEmailChange}
                            className={`w-full font-semibold h-12 sm:h-[50px] rounded-md border ${
                              isEmailValid
                                ? 'border-[#565656]'
                                : 'border-red-500'
                            } bg-[#2f2f2f] text-white placeholder-[#eae8e8] px-4 text-xs font-['Inter'] focus:ring-0`}
                          />
                        </div>
                        <button
                          type="button"
                          onClick={handleSubmit}
                          className="w-full font-semibold h-12 sm:h-[50px] flex justify-between items-center rounded-md bg-white px-4 text-xs font-medium font-['Inter'] text-black hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                        >
                          Request Demo
                          <ArrowNarrowRight />
                        </button>
                        {!isEmailValid && (
                          <p className="text-red-500 text-xs text-left">
                            Please enter a valid email address
                          </p>
                        )}
                        {error && (
                          <p className="text-red-500 text-xs text-left">
                            {error}
                          </p>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default DemoModal

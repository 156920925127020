'use client'

import { HtEventsBrowser } from '@ht-sdks/events-sdk-js-browser'
import { v4 as uuidv4 } from 'uuid'
import { canTrack, getKetchConsent } from '@/lib/ketch'
import { sha256 } from 'js-sha256'

const isProduction = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
const hightouchKey = isProduction
  ? process.env.NEXT_PUBLIC_HITOUCH_API_KEY
  : process.env.NEXT_PUBLIC_HITOUCH_API_KEY_DEV
const environment = process.env.NEXT_PUBLIC_VERCEL_ENV || 'development'
const originalHtevents = HtEventsBrowser.load(
  { writeKey: hightouchKey || '' },
  {
    apiHost: 'us-east-1.hightouch-events.com',
    user: {
      sessions: {
        autoTrack: true,
        timeout: 30 * 60 * 1000, // 30 minutes
      },
    },
  },
)

async function checkConsent() {
  const consentState = await getKetchConsent()
  return canTrack(consentState)
}

async function formatedConsent() {
  const ketchConsent = await getKetchConsent()
  return {
    defaultDestinationBehavior: 'imply',
    destinationPreferences: {},
    categoryPreferences: {
      marketingAndAnalytics: ketchConsent.analytics || false,
      advertising: ketchConsent.personalization || false,
      functional: ketchConsent.product_enhancement || false,
    },
  }
}

//wrapper for consent check and environment
export const htevents = {
  ...originalHtevents,
  page: async (...args: Parameters<typeof originalHtevents.page>) => {
    const consent = await checkConsent()
    if (consent) {
      const [category, name, properties, options = {}, ...rest] = args
      const consentData = await formatedConsent()
      return originalHtevents.page(
        category,
        name,
        properties,
        { ...options, consent: consentData, environment },
        ...rest,
      )
    }
  },
  track: async (...args: Parameters<typeof originalHtevents.track>) => {
    const consent = await checkConsent()
    if (consent) {
      const [event, properties, options = {}, ...rest] = args
      const consentData = await formatedConsent()
      return originalHtevents.track(
        event,
        properties,
        { ...options, consent: consentData, environment },
        ...rest,
      )
    } else {
      console.log(
        `ketch:: Event tracking blocked for "${args[0]}" due to consent restrictions.`,
      )
    }
  },
  identify: async (...args: Parameters<typeof originalHtevents.identify>) => {
    const consent = await checkConsent()
    if (consent) {
      const [userId, traits, options = {}, ...rest] = args
      const consentData = await formatedConsent()
      return originalHtevents.identify(
        userId,
        traits,
        { ...options, consent: consentData, environment },
        ...rest,
      )
    } else {
      console.log(
        'ketch:: User identification blocked due to consent restrictions.',
      )
    }
  },
}

function generateLeadId() {
  return uuidv4()
}

export async function identifyUser(existingLeadId: string | null = null) {
  let leadId = existingLeadId || generateLeadId()

  if (!existingLeadId) {
    await htevents.identify(leadId, { lead_id: leadId })
  }

  return leadId
}

export async function clientTrackPageView(
  url: string,
  leadId: string,
  anchor: string | null,
) {
  await htevents.page(
    'www',
    url,
    {
      url: url,
      lead_id: leadId,
    },
    { anchor },
  )
}

export function setAnonymousId(anonymousId: string) {
  htevents.setAnonymousId(anonymousId)
}

export async function track(event: string, properties: Object = {}) {
  await htevents.track(event, properties)
}

export async function trackConsentEvent(ketchId: string) {
  await htevents.track('Consent Updated', {
    ketch_id: ketchId,
  })
}

export async function trackDemoRequest(email: string) {
  const hashedEmail = sha256(email.toLowerCase().trim())

  await originalHtevents.track(
    'Demo Requested',
    {
      email: email,
      hashed_email: hashedEmail,
    },
    { environment },
  )
}

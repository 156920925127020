export const ArrowNarrowRight = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="arrow-narrow-right">
      <path
        id="Icon"
        d="M3 9H15M15 9L10.5 4.5M15 9L10.5 13.5"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)
